<template>
  <div id="cancel" class="sub_wrap">
    <!--    20231024 lgu+ 가입/해지 불가 모달-->
    <!--    <LayerPopup v-if="dispLgupPopup" :popId="'notice_popup'">
      <template v-slot:popContents>
        <div class="contents">
          <h4>[LG U+ 시스템 점검 안내]</h4>
          <div class="pop_contents">
            LG U+ 이동통신사에서 진행하는 시스템 점검에 의해 LG U+ 이용 고객의 서비스 해지가 일시 중단 되었습니다.<br /><br />
            · 일정 : 2023년 9월 22일(금) 22:00 ~ 2023년 9월 25일(월) 08:00<br /><br />
            자세한 내용은 공지사항 확인을 부탁 드립니다.
          </div>
          <ul class="pop_btns">
            <li><button type="button" class="pop_btn cancel" @click="hideLayerPopup">취소</button></li>
            <li><button type="button" class="pop_btn access" @click="noticeDetail">자세히 보기</button></li>
          </ul>
        </div>
      </template>
    </LayerPopup>-->
    <LayerPopup v-if="dispCaptcha" :popId="'captcha'">
      <template v-slot:popContents>
        <div class="contents">
          <h4>하단 <strong>보안문자를 입력</strong>하세요</h4>
          <div class="captcha_wrap">
            <div class="captcha_insert">
              <span class="img"><img :src="CaptchaImage" alt="captcha image" /></span>
              <div class="forms">
                <button type="button" class="refresh" @click="getCaptCha">새로고침</button>
                <span class="forms_ip">
                  <input
                    type="number"
                    name="captcha_num"
                    id="captcha_num"
                    placeholder="보안문자 입력"
                    min="0"
                    max="9999"
                    maxlength="4"
                    v-model="CaptchaNumber"
                    @input="insertLengthCheck(CaptchaNumber, 4)"
                    @keyup.enter="submitCaptCha"
                  />
                </span>
              </div>
            </div>
            <small>화면에 출력된 문자를 정확하게 입력해 주세요.</small>
          </div>
          <ul class="pop_btns">
            <li><button type="button" class="pop_btn cancel" @click="cancelCaptcha">취소</button></li>
            <li>
              <button id="captcha_submit" type="button" class="pop_btn access" @click="submitCaptCha">확인</button>
            </li>
          </ul>
        </div>
      </template>
    </LayerPopup>
    <section class="sub_sec">
      <SubPageExplain>
        <template>
          <strong class="title">서비스를 해지하기 전에<br />아래 내용을 꼭 확인해 주세요.</strong>
        </template>
        <template v-solt:desc>
          <ul class="cancel_notice">
            <li class="warn">
              * 서비스 해지 시 '휴대폰 위치 찾기', '습득자와 영상통화', '분실폰 잠금' 등 휴대폰 분실보호에서 제공하는
              회수 기능들을 더 이상 이용할 수 없습니다.
            </li>
            <li class="warn">
              * 백업 데이터는 서비스 해지와 동시에 즉시 삭제되어 복원이 어려우니 해지는 신중히 결정하시기 바랍니다.
            </li>
            <li class="u">* 가입 당일 해지하신 고객님은 요금이 발생하지 않습니다.</li>
            <li>* 서비스 이용요금은 매월 사용일 수만큼 계산되어 청구됩니다.</li>
          </ul>
        </template>
      </SubPageExplain>
      <CancelSubmit>
        <template v-slot:forms>
          <div class="forms_wrap">
            <strong class="forms_tit">통신사 선택</strong>
            <div class="tel_btns">
              <button
                v-for="(Item, Index) in cert.telecom"
                :key="Index"
                :value="Index"
                type="button"
                class="tel_btn"
                :class="Index === cert.tel && 'selected'"
                @click="changeTel(Index)"
              >
                {{ Item.name }}
              </button>
            </div>
          </div>
          <div class="forms_wrap">
            <strong class="forms_tit">휴대폰 번호</strong>
            <span class="forms_ip">
              <span class="forms_select">
                <select name="phone_select" id="phone_select" v-model="cert.phoneNumSelect">
                  <option v-for="opt in cert.phoneHeader" :value="opt" :key="opt">{{ opt }}</option>
                </select>
              </span>
              <input
                type="number"
                name="phone_insert"
                id="phone_insert"
                placeholder="‘-’ 제외 하고 입력해 주세요"
                min="0"
                max="99999999"
                maxlength="8"
                v-model="cert.phoneNumInsert"
                @input="insertLengthCheck(cert.phoneNumInsert, 8, 'CHG_PHONENUM')"
                @keyup.enter="getCaptCha"
              />
            </span>
          </div>
          <SubmitButton
            :disable="$store.state.loading"
            :btnObj="{ class: 'more', text: '인증번호 받기' }"
            @btnEvt="getCaptCha"
          />
          <div class="forms_wrap">
            <strong class="forms_tit">인증번호 입력</strong>
            <span class="forms_ip">
              <input
                type="number"
                name="cert_insert"
                id="cert_insert"
                placeholder="인증번호를 입력해주세요."
                min="0"
                max="9999"
                maxlength="4"
                v-model="cert.CertNumber"
                @input="insertLengthCheck(cert.CertNumber, 4, 'CHG_CERTNUM')"
                @keyup.enter="cancelService"
              />
              <span class="count">{{ timer.minutes }}:{{ timer.seconds }}</span>
            </span>
          </div>
        </template>
        <template v-slot:button>
          <SubmitButton
            :disable="$store.state.loading"
            :btnObj="{ class: 'submit', text: '서비스 해지' }"
            @btnEvt="cancelService"
          />
        </template>
      </CancelSubmit>
    </section>
  </div>
</template>

<script>
import SubPageExplain from "@/components/SubPageExplain.vue"
import SubmitButton from "@/components/SubmitButton.vue"
import LayerPopup from "@/components/LayerPopup.vue"
import CancelSubmit from "@/components/Cancel/CancelSubmit.vue"
import { mapActions, mapMutations, mapState } from "vuex"
import constants from "@/service/constant/store"

export default {
  name: "Cancel",
  components: {
    SubPageExplain,
    SubmitButton,
    LayerPopup,
    CancelSubmit,
  },
  data() {
    return {
      dispCaptcha: false,
      CryptoTranID: "",
      CaptchaImage: "",
      CaptchaNumber: "",
      // 20231024 lgu+ 가입/해지 불가 모달
      // dispLgupPopup: false,
    }
  },
  mounted() {
    this.resetCertData()
    this.resetTimer()
  },
  destroyed() {
    this.$store.commit(constants.RESET_CERT_DATA)
    this.$store.dispatch(constants.RESET_TIMER)
  },
  computed: {
    ...mapState(["cert", "timer"]),
  },
  methods: {
    ...mapMutations({
      resetCertData: constants.RESET_CERT_DATA,
    }),
    ...mapActions({
      countDown: constants.COUNT_DOWN,
      resetTimer: constants.RESET_TIMER,
      startTimer: constants.START_TIMER,
      changeTel: constants.CHANGE_TEL,
      setTranId: constants.SET_TRANID,
    }),
    insertLengthCheck(value, limit, commitMsg) {
      const validResult = this.$validateRegExp(value, limit)
      if (commitMsg) this.$store.commit(constants[commitMsg], validResult)
      else this.CaptchaNumber = validResult
    },
    async getCaptCha() {
      document.getElementById("phone_insert")?.blur()
      document.getElementById("captcha_button")?.blur()

      if (this.cert.telecom == "") {
        alert("통신사를 선택하여 주십시오.")
        return false
      }
      if (this.cert.phoneNumInsert == "" || this.cert.phoneNumInsert.length < 8) {
        alert("휴대폰번호를 입력하여 주십시오.")
        return false
      }

      const httpData = {}
      httpData.Header = {
        CmdType: "010010",
        RequestID: this.$guid(),
      }

      this.$store.commit(constants.IS_LOADING)
      try {
        const { data } = await this.$SRSServer(httpData)
        if (data.Header.ResultCd === "00000000") {
          this.dispCaptcha = true
          this.CryptoTranID = data.Body.TranID
          this.CaptchaImage = " data:image/png;base64," + data.Body.ImgB64
        } else {
          alert(data.Header.ErrMsg)
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.$store.commit(constants.IS_LOADING)
      }
    },
    async submitCaptCha() {
      document.getElementById("captcha_num")?.blur()
      document.getElementById("captcha_submit")?.blur()

      if (this.CaptchaNumber.length !== 4) {
        alert("보안문자를 입력해주세요.")
        return false
      }

      const httpData = {}
      httpData.Header = {
        CmdType: "010011",
        CallApp: "CancelPage",
        RequestID: this.$guid(),
      }
      httpData.Body = {
        TranID: this.CryptoTranID,
        Telecom: Number(this.cert.tel),
        PNumber: this.cert.phoneNumSelect + this.cert.phoneNumInsert,
        CryptoNumber: this.CaptchaNumber,
      }

      this.$store.commit(constants.IS_LOADING)
      try {
        const { data } = await this.$SRSServer(httpData)
        if (data.Header.ResultCd !== "10010001") {
          alert(data.Header.ResultMsg)
          this.$store.commit(constants.IS_LOADING)
          if (data.Header.ResultCd === "10020005" || data.Header.ResultCd === "10020012") {
            this.cancelCaptcha()
          }
        } else {
          this.setTranId(data.Body.TranID)
          this.resetTimer()
          this.startTimer(data.Body.TimeOut)
          alert("인증번호가 전송되었습니다")
          this.$store.commit(constants.IS_LOADING)
          this.cancelCaptcha()
        }
      } catch (error) {
        console.log(error)
      }
    },
    cancelCaptcha() {
      this.dispCaptcha = false
      this.CryptoTranID = ""
      this.CaptchaImage = ""
      this.CaptchaNumber = ""
      if (this.$store.state.loading) {
        this.$store.commit(constants.IS_LOADING)
      }
    },
    async cancelService() {
      document.getElementById("cert_insert")?.blur()
      document.getElementById("submit_btn")?.blur()

      if (this.cert.tranID === "") {
        alert("인증완료 후 이용해주세요. .")
        return false
      }

      if (Number(this.cert.tel) === "") {
        alert("통신사를 선택하여 주세요.")
        return false
      }
      if (this.cert.phoneNumInsert === "") {
        alert("휴대폰번호를 입력하여 주세요.")
        return false
      }
      if (this.cert.CertNumber.length !== 4) {
        alert("인증번호를 입력하여 주세요.")
        return false
      }

      const httpData = {}
      httpData.Header = {
        CmdType: "010007",
        CallApp: "www",
        TransactionID: this.$guid(),
      }
      httpData.Body = {
        Telecom: Number(this.cert.tel),
        PNumber: this.cert.phoneNumSelect + this.cert.phoneNumInsert,
        PCode: "Datauniverse",
        CallType: "web",
        AuthType: "delete",
        ApprovalNumber: Number(this.cert.CertNumber),
        TranID: this.cert.tranID,
      }

      this.$store.commit(constants.IS_LOADING)
      try {
        const { data } = await this.$SRSServer(httpData)
        if (data.Header.ResultCd !== "10010002") {
          alert(data.Header.ResultMsg)
        } else {
          alert("해지가 완료되었습니다")
          this.resetCertData()
          this.resetTimer()
          this.$router.push("/home")
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.$store.commit(constants.IS_LOADING)
      }
    },
    // 20231024 lgu+ 가입/해지 불가 모달
    /*hideLayerPopup() {
      this.dispLgupPopup = false
    },
    noticeDetail() {
      this.dispLgupPopup = false
      const index = window.location.href.indexOf("local") > 0 || window.location.href.indexOf("dev") > 0 ? 19 : 72
      this.$router.push(`/notice/detail/${index}`)
    },*/
  },
  created() {
    // 20231024 lgu+ 가입/해지 불가 모달
    /*const sd = dayjs("2023-10-16 00:00:00")
    const ed = dayjs("2023-10-23 08:00:00")
    const now = dayjs()
    this.dispLgupPopup = now.isAfter(sd) && now.isBefore(ed)*/
  },
}
</script>
