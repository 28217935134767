<template>
  <div class="submit_wrap">
    <button type="button" class="submit_btn" :class="btnObj.class" :disabled="disable" @click="handleClick">
      {{ btnObj.text }}
    </button>
  </div>
</template>

<script>
export default {
  name: "SubmitButton",
  props: {
    btnObj: {
      type: Object,
    },
    disable: {
      type: Boolean,
      default: false,
    },
    btnEvt: {
      type: Function,
    },
  },
  methods: {
    handleClick() {
      this.$emit("btnEvt")
    },
  },
}
</script>
