<template>
  <div class="cancel_submit">
    <section class="section_submit cancel_sec">
      <h4>서비스 해지</h4>
      <slot name="forms"></slot>
      <slot name="button"></slot>
    </section>
  </div>
</template>

<script>
export default {
  name: "CancelSubmit",
}
</script>
